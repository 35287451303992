.trendAnalysis {
  border: 1px solid rgba(59, 174, 218, 0.8);
  margin-bottom: 10px;
  width: 100%;
}

.trendAnalysisLegend {
  color: #444;
  font-weight: 500;
}

.timeOfDayChartContainer {
  flex: 0 0 50%;
}

.allTimesOfDayChartsHeader {
  width: 100%;
  text-align: center;
  color: #555;
  font-weight: 500;
}

.allTimesOfDayChartsContainer {
  display: flex;
  flex-flow: row wrap
}

.chartTitle {
  text-align: center;
  text-transform: capitalize;
  color: #555;
  font-weight: 500;
}
