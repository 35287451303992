.statistics {
  display: flex;
  flex-wrap: wrap;
}

.chartControl {
  display: flex;
  flex: 0 0 50%;
  justify-content: flex-start
}

.dateInput {
  padding-right: 10px;
}

.dateInputLabel {
  display: block;
}

.monthButtons {
  display: flex;
  flex: 0 0 50%;
  justify-content: flex-end;
}

.charts {
  display: flex;
}

.monthlyChart {
  flex: 0 0 100%;
  margin-bottom: 20px;
}

.weekdayChart {
  flex: 0 0 40%;
}

.timeOfDayCharts {
  flex: 0 0 60%;
  min-width: 450;
}

.adherenceTrendAnalysis {
  display: flex;
  flex: 0 0 100%;
  margin-top: 10px;
}
